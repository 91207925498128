<template>
  <ion-page>
    <header-toolbar>
      <ion-title>{{ __('interface.sessions') }}</ion-title>
    </header-toolbar>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <sessions-list v-for="(items, key) in sessionGroups" :title="key" :key="key" :items="items"/>
      <ion-list v-if="Object.keys(sessionGroups).length <= 0">
        <ion-item >
          <ion-label v-text="__('interface.no-upcoming-sessions')"></ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonRefresher, IonRefresherContent, loadingController} from '@ionic/vue'
import {API} from '@/utils/apiWrapper'
import {Auth} from '@aws-amplify/auth'
import AuthView from '@/views/AuthService'
import {layout} from '@/utils/layout'
import SessionsList from '@/views/Sessions/SessionsIndex'
import HeaderToolbar from "../../layouts/HeaderToolbar";
import BaseSessions from "./BaseSessions";

export default {
  name: 'Sessions',
  mixins: [AuthView, layout, BaseSessions],
  components: {
    HeaderToolbar,
    SessionsList,
    IonPage,
    IonRefresherContent,
    IonRefresher,
  },
  data() {
    return {
      sessionGroups: []
    }
  },
  methods: {
    loadData() {
      const todayIsoString = new Date().toISOString().split('T')[0]
      const nextWeek = new Date()
      nextWeek.setDate(nextWeek.getDate() + 7)
      const nextWeekIsoString = nextWeek.toISOString().split('T')[0]

      const params = {
        queryStringParameters: {
          start: todayIsoString,
          end: nextWeekIsoString
        }
      }

      return API.get('TheSpottApi', '/sessions-customer', params).then(response => {
        this.sessionGroups = this.parseResponse(response)
      })
    },

    doRefresh(event) {
      this.loadData()
        .catch((err) => console.log(err))
        .finally(() => {
          event.target.complete()
        })
    }
  },
  async ionViewWillEnter() {
    await this.checkAuth()
  },
  async ionViewDidEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()

    Auth.currentAuthenticatedUser().catch(() => {
      this.$router.push('/login')
    })

    this.loadData().finally(() => {
      loading.dismiss()
    })
  }
}
</script>
